import React from "react"
import RestManager from "../Shared/RestManager"
import Constants from "../Shared/Constants"
import MasksTable from "./MasksTable"
import {Container} from "semantic-ui-react"
import LocalStorageManager from "../Shared/LocalStorageManager"
import SupplierStatus from "./SupplierStatus"
import HelpDocuments from "../Shared/HelpDocuments"

class MasksList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isFetching: false,
            currentDocumentsStatus: [],
            currentSupplierStatus: [],
        }
    }

    username = LocalStorageManager.getKey("username")

    componentDidMount() {
        this.fetchDocumentsStatus()
        this.fetchSupplierStatus()
    }

    fetchSupplierStatus(){
        this.setState({...this.state, isFetching: true})
        RestManager.get(Constants.CURRENT_SUPPLIER_STATUS, {"supplier.email": this.username})
            .then(result => {
                this.setState({currentSupplierStatus: result.data})
                this.setState({...this.state, isFetching: false})
            })
            .catch(error => {
                this.setState({...this.state, isFetching: false})
            })
    }

    fetchDocumentsStatus() {
        this.setState({...this.state, isFetching: true})
        RestManager.get(Constants.MANDATORY_DOCUMENTS_SECTION, {"email": this.username})
            .then(result => {
                this.setState({currentDocumentsStatus: result.data})
                this.setState({...this.state, isFetching: false})
            })
            .catch(error => {
                this.setState({...this.state, isFetching: false})
            })
    }

    render() {
        return (
            <>
                <Container as={"h2"} textAlign={"center"}>
                    Stato Fornitore
                </Container>
                <HelpDocuments/>
                {/*@todo Normalize as below.*/}
                {0 === this.state.currentSupplierStatus.length ? "Caricamento..." : <SupplierStatus data={this.state.currentSupplierStatus}/>}

                <Container as={"h2"} textAlign={"center"}>
                    Documenti richiesti
                </Container>
                <p>
                    {this.state.isFetching ? "Caricamento..." : ""}
                </p>
                <MasksTable data={this.state.currentDocumentsStatus}/>
            </>
        )
    }

}

export default MasksList