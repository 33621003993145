import React from "react"
import { navigate } from "gatsby"
import { handleLogin, isLoggedIn } from "./Shared/Auth"
import { Button, Container, Form, Input, Segment } from "semantic-ui-react"
import Constants from "./Shared/Constants"
import { toast } from "react-toastify"

class LoginPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      username: "",
      password: "",
    }
  }

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit = event => {
    event.preventDefault()

    handleLogin(this.state).then(response => {
      navigate("/app/home")
    })
  }

  doRegister (event) {
    event.preventDefault()
    if (Constants.REGISTRATION_ENABLED) {
      navigate("/app/register")
    } else {
      toast.error("La registrazione è temporaneamente disabilitata", { autoClose: 3500 })
    }
  }

  render () {
    if (isLoggedIn()) {
      navigate("/app/home")
    }

    return (
      <>
        <Form
          onSubmit={event => {
            this.handleSubmit(event)
          }}
        >
          <Segment>
            <Form.Field>
              <label htmlFor="username">Username</label>
              <Input
                id="username"
                fluid
                name="username"
                type="username"
                onChange={this.handleUpdate}
              />
            </Form.Field>
            <Form.Field>
              <label htmlFor="password">Password</label>
              <Input
                id="password"
                fluid
                name="password"
                type="password"
                onChange={this.handleUpdate}
              />
            </Form.Field>
            <Container>
              <Button type="submit" color="orange">
                Login
              </Button>
              Oppure &nbsp;
              <Button onClick={event => this.doRegister(event)} color="grey">
                Registrazione
              </Button>
            </Container>
          </Segment>
        </Form>
      </>
    )
  }
}

export default LoginPage