import React from "react"
import { Container, Grid, Icon, Segment } from "semantic-ui-react"
import { Link } from "gatsby"
import RestManager from "./Shared/RestManager"
import Constants from "./Shared/Constants"
import LocalStorageManager from "./Shared/LocalStorageManager"
import SupplierInfo from "./SupplierInfo"

class Home extends React.Component {

  componentDidMount () {
    this.fetchSupplierStatus()
  }

  constructor (props) {
    super(props)
    this.state = {
      isFetching: false,
      supplier: {},
    }
  }

  username = LocalStorageManager.getKey("username")

  fetchSupplierStatus () {
    this.setState({ ...this.state, isFetching: true })
    RestManager.get(Constants.INFO_SUPPLIER, { "email": this.username })
      .then(result => {
        this.setState({ ...this.state, isFetching: false })
        this.setState({ supplier: result.data[0] })
      })
      .catch(error => {
        this.setState({ ...this.state, isFetching: false })
      })
  }

  render () {
    return (
      <>
        <Container as={"h1"} textAlign={"center"}>Dashboard</Container>
        <SupplierInfo supplier={this.state.supplier}/>

        <Container as={"h1"} textAlign={"center"}>Menu</Container>

        <Segment>
          <Grid columns={"2"}>
            <Grid.Row>
              <Grid.Column textAlign={"center"}>
                <Icon name={"file alternate outline"} size={"large"}/>
                <Link to={"/app/masksList"}>Documenti richiesti</Link>
              </Grid.Column>
              <Grid.Column textAlign={"center"}>
                <Icon name={"user outline"} size={"large"}/>
                <Link to="/app/profile">Profilo</Link>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </>
    )
  }
}

// {this.state.supplier === undefined ? "a": this.state.supplier.email}
export default Home