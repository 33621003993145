import React from "react"

import {Segment, List} from "semantic-ui-react";

class SupplierStatus extends React.Component{
    render() {
        return(
            <Segment color={"red"} size={"big"} raised>
                <List bulleted animated>
                    <List.Item>{this.props.data[0].supplierStatus.description}</List.Item>
                    {this.props.data[0].description1 ? <List.Item>{this.props.data[0].description1}</List.Item> : ""}
                    {this.props.data[0].description2 ? <List.Item>{this.props.data[0].description2}</List.Item> : ""}
                </List>
            </Segment>
        )
    }
}

export default SupplierStatus