import React from "react"
import {List, Grid, Header} from "semantic-ui-react"

class SupplierInfo extends React.Component {
    render() {

        let supplierTypes = []
        let supplierCommodityTypes = []

        if (Object.keys(this.props.supplier).length !== 0) {
            for (const type of this.props.supplier.supplyTypes) {
                supplierTypes.push(
                    <List.Item key={type.id}>
                        <List.Content as={'h3'}>
                            {type.description}
                        </List.Content>
                    </List.Item>
                )
            }
        }

        if (Object.keys(this.props.supplier).length !== 0) {
            for (const commodityType of this.props.supplier.supplyCommodityTypes) {
                supplierCommodityTypes.push(
                    <List.Item key={commodityType.id}>
                        <List.Content as={'h3'}>
                            {commodityType.description}
                        </List.Content>
                    </List.Item>
                )
            }
        }

        return (
            <Grid columns={"3"}>
                <Grid.Row>
                    <Grid.Column>
                        <Header as='h3'>
                            <Header.Subheader>
                                Nome
                            </Header.Subheader>
                            {this.props.supplier.email}
                        </Header>
                        <Header as='h3'>
                            <Header.Subheader>
                                Ragione Sociale
                            </Header.Subheader>
                            {this.props.supplier.name}
                        </Header>
                        <Header as='h3'>
                            <Header.Subheader>
                                Partita Iva
                            </Header.Subheader>
                            {this.props.supplier.vatNumber}
                        </Header>
                        <Header as='h3'>
                            <Header.Subheader>
                                Tipologie
                            </Header.Subheader>
                            <List>
                                {supplierTypes}
                            </List>
                        </Header>
                        <Header as='h3'>
                            <Header.Subheader>
                                Classe Merceologica
                            </Header.Subheader>
                            <List>
                                {supplierCommodityTypes}
                            </List>
                        </Header>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}

export default SupplierInfo