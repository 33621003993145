import React from "react"
import { Divider, Form, Segment, Table } from "semantic-ui-react"

class CommodityType extends React.Component {
  render () {
    let sections = []
    let handleUpdate = this.props.handleUpdate
    let checked = this.props.checked
    const nCols = 3

    function renderRow (commodityRow) {
      let row = []

      for (const commodityType of commodityRow) {
        row.push(
          <Table.Cell key={commodityType.id} style={{ padding: "3px 5px 3px 5px" }}>
            <Form.Field
              label={commodityType.description}
              control="input"
              type="checkbox"
              name={commodityType.id}
              onChange={handleUpdate}
              checked={checked.includes("" + commodityType.id + "")}
            />
          </Table.Cell>,
        )
      }

      return row
    }

    function renderTable (commodityTypes) {
      let subSection = []
      const nRows = Math.trunc(commodityTypes.length / nCols) + commodityTypes.length % nCols

      for (let i = 0; i < nRows; i++) {
        let start = i * nCols
        let commodityRow = commodityTypes.slice(start, start + nCols)
        subSection.push(
          <Table.Row verticalAlign="top" key={i}>
            {renderRow(commodityRow)}
          </Table.Row>,
        )
      }

      return subSection
    }

    function renderType (type) {
      return (
        <Table size="small">
          <Table.Body>
            {renderTable(type.supplyCommodityTypes)}
          </Table.Body>
        </Table>
      )
    }

    for (const [i, data] of this.props.data.entries()) {
      if (this.props.types[i]) {
        sections.push(
          <Segment color={"red"} size={"big"} raised key={data.id}>
            {data.description}
            <Divider/>
            {renderType(data)}
          </Segment>,
        )
      }
    }

    return (
      <Segment.Group>
        {sections}
      </Segment.Group>
    )
  }
}

export default CommodityType